import React, { Component } from "react"
import ContactForm from "../components/contact_form"
import { graphql } from 'gatsby'

class Contact extends Component {
    render() {
        return <div className="main-content">
            {this.props.data.allMarkdownRemark.edges.map(({ node }) => {
                return <p key={node.id} dangerouslySetInnerHTML={{ __html: node.html }} />
            })}
            <ContactForm />
        </div>
    }
}

export default Contact

export const pageQuery = graphql`
    query contactQuery {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/contact/"}}) {
        edges {
          node {
            id
            html
          }
        }
      }
    }
`